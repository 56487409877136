import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVAVqMQe-Xi7vXH5ISusliFShxgTJtDns",
  authDomain: "telegram-bot-abc.firebaseapp.com",
  projectId: "telegram-bot-abc",
  storageBucket: "telegram-bot-abc.appspot.com",
  messagingSenderId: "692003856381",
  appId: "1:692003856381:web:259a4030eec23fa2910394",
  measurementId: "G-0PC7FF4NVY",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export default db;

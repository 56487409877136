import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="main">
      <a
        style={{ textDecoration: "none", color: "turquoise" }}
        href="https://botlist.infotelbot.com"
      >
        <h1>Telegram Bot List</h1>
      </a>
      <div className="searchbtn">
        <Link to="/category">
          <button className="search">Search Bots List</button>
        </Link>
      </div>
      <Link to="/new">
        <div className="addbtn">
          <button id="addbtn">Add to Bot List</button>
        </div>
      </Link>
      <footer>
        @InFoTel 2021 All Rights Reserved. <br />
        Made With ❤ in India
      </footer>
    </div>
  );
}

export default Home;

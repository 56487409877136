import React, { useState, useEffect } from "react";
import "./Category.css";
import db from "./firebase";
import BotList from "./BotList";

function Category() {
  const [link, setLink] = useState("Group Manager Bots");
  const [bots, setBots] = useState([]);

  var arr = [
    "Ai Bots",
    "Android Tech News Bot",
    "Anime Downloading",
    "Anonymous Chatting Bots",
    "Anonymous Sender Bots",
    "Anti Deleted Accounts Bot",
    "Anti Service Messages Bots",
    "Anti Userbots Bots",
    "Anti Virus Bots",
    "APP ID & API HASH Extractor Bots",
    "Application Downloader Bots",
    "Attach Bots",
    "Audio Extractor Bots",
    "Auto Welcome Kick Bots",
    "Automatic Caption for Channel Posts",
    "Automatic Reaction Bots",
    "Bot creator Bots",
    "Compiler Bots",
    "Corona Virus Statistics Bots",
    "DC Finder Bots",
    "Debts Tracker Bots",
    "DelDog Bots",
    "Detach Bot",
    "Discussion Cleaner Bots",
    "Discussion Unpin Bots",
    "DMCA Copyright Delete Bots",
    "Download Button For Channels Bots",
    "Email Tools Bots",
    "Facebook Download Bots",
    "File Converters Bots",
    "File Folder Manager Bots",
    "File storing Bots",
    "File To Link Bots",
    "Filter Bots",
    "Force Subscribe Bots",
    "Game Bots",
    "GDrive Bots",
    "Gif Finder Bots",
    "Gist Bots",
    "GitHub Downloader Bots",
    "GPLinks Shortener Bots",
    "Group Filter Bots",
    "Group Manager Bots",
    "Hotstar Bots",
    "Image To PDF Bots",
    "Image to Text (OCR) Bots",
    "Inline Bots",
    "Instagram Download Bots",
    "Instant View Generator Bots",
    "Jio Saavn Downloader",
    "JSON Bots",
    "Link Shortener Bots",
    "Markdown Generator Bots",
    "Media Fire Bots",
    "Media Info Bots",
    "Messages Deleting Bots",
    "Movies / Series Download Bot",
    "Movies / Series Info Bots",
    "Multi Purpose Bots",
    "Music / Songs Related Bots",
    "MX Player Download Bots",
    "OneDrive Uploader",
    "Other Bots",
    "Password Generator Bots",
    "Pawned Bots",
    "PDF related Bots",
    "Permanent File to Link Bots",
    "Photo Finder Bots",
    "Ping Bots",
    "Product Price Tracker Bots",
    "Product Search Bots",
    "QR Code Scanner/Generator Bots",
    "Quiz Bots",
    "Reddit Bots",
    "Reminder Bots",
    "Remove Keyboard from Group Bot",
    "Rename Bots",
    "Reverse Image Search Bots",
    "RSS Bots",
    "Screenshot Generate Bots",
    "Search Bots",
    "Secret Message Bot",
    "Session String Generator Bot",
    "Spam Protection Bots",
    "Specific Language Blocker",
    "Sports Bots",
    "Stickers & Gifs Bots",
    "Study Materials Bots",
    "Subtitle Bots",
    "Tag Alerts Bot",
    "Telegram Backend Bots",
    "Telegram Creation Date Finder Bots",
    "Telegraph Bots",
    "Temporary Mail Bots",
    "Text Formatting Bots",
    "Text Formatting Bots",
    "Text Sticker Bots",
    "Thumbnail Changer Bots",
    "Tiktok Video Downloader",
    "Torrent Downloader Bots",
  ];

  const getBots = () => {
    console.log(link);

    db.collection(`${link}`)
      .orderBy("time", "asc")
      .onSnapshot((snapshot) => {
        let tempBots = [];

        tempBots = snapshot.docs.map((doc) => ({
          id: doc.id,
          bot: doc.data(),
        }));

        setBots(tempBots);
        console.log(tempBots);
      });
  };
  useEffect(() => {
    getBots();
  }, [link]);

  console.log(bots);

  const cat1 = () => {
    setLink("Ai Bots");
  };
  const cat2 = () => {
    setLink("Android Tech News Bots");
  };
  const cat3 = () => {
    setLink("Anime Downloading");
  };
  const cat4 = () => {
    setLink("Anonymous Chatting Bots");
  };
  const cat5 = () => {
    setLink("Anonymous Sender Bots");
  };
  const cat6 = () => {
    setLink("Anti Deleted Accounts Bot");
  };
  const cat7 = () => {
    setLink("Anti Service Messages Bots");
  };
  const cat8 = () => {
    setLink("Anti Userbots Bots");
  };
  const cat9 = () => {
    setLink("Anti Virus Bots");
  };
  const cat10 = () => {
    setLink("APP ID & API HASH Extractor Bots");
  };
  const cat11 = () => {
    setLink("Application Downloader Bots");
  };
  const cat12 = () => {
    setLink("Attach Bots");
  };
  const cat13 = () => {
    setLink("Audio Extractor Bots");
  };
  const cat14 = () => {
    setLink("Auto Welcome Kick Bots");
  };
  const cat15 = () => {
    setLink("Automatic Caption for Channel Posts");
  };
  const cat16 = () => {
    setLink("Automatic Reaction Bots");
  };
  const cat17 = () => {
    setLink("Bot creator Bots");
  };
  const cat18 = () => {
    setLink("Compiler Bots");
  };
  const cat19 = () => {
    setLink("Corona Virus Statistics Bots");
  };
  const cat20 = () => {
    setLink("DC Finder Bots");
  };
  const cat21 = () => {
    setLink("Debts Tracker Bots");
  };
  const cat22 = () => {
    setLink("DelDog Bots");
  };
  const cat23 = () => {
    setLink("Detach Bot");
  };
  const cat24 = () => {
    setLink("Discussion Cleaner Bots");
  };
  const cat25 = () => {
    setLink("Discussion Unpin Bots");
  };
  const cat26 = () => {
    setLink("DMCA Copyright Delete Bots");
  };
  const cat27 = () => {
    setLink("Download Button For Channels Bots");
  };
  const cat28 = () => {
    setLink("Email Tools Bots");
  };
  const cat29 = () => {
    setLink("Facebook Download Bots");
  };
  const cat30 = () => {
    setLink("File Converters Bots");
  };
  const cat31 = () => {
    setLink("File Folder Manager Bots");
  };
  const cat32 = () => {
    setLink("File storing Bots");
  };
  const cat33 = () => {
    setLink("File To Link Bots");
  };
  const cat34 = () => {
    setLink("Filter Bots");
  };
  const cat35 = () => {
    setLink("Force Subscribe Bots");
  };
  const cat36 = () => {
    setLink("Game Bots");
  };
  const cat37 = () => {
    setLink("GDrive Bots");
  };
  const cat38 = () => {
    setLink("Gif Finder Bots");
  };
  const cat39 = () => {
    setLink("Gist Bots");
  };
  const cat40 = () => {
    setLink("GitHub Downloader Bots");
  };
  const cat41 = () => {
    setLink("GPLinks Shortener Bots");
  };
  const cat42 = () => {
    setLink("Group Filter Bots");
  };
  const cat43 = () => {
    setLink("Group Manager Bots");
  };
  const cat44 = () => {
    setLink("Hotstar Bots");
  };
  const cat45 = () => {
    setLink("Image To PDF Bots");
  };
  const cat46 = () => {
    setLink("Image to Text (OCR) Bots");
  };
  const cat47 = () => {
    setLink("Inline Bots");
  };
  const cat48 = () => {
    setLink("Instagram Download Bots");
  };
  const cat49 = () => {
    setLink("Instant View Generator Bots");
  };
  const cat50 = () => {
    setLink("Jio Saavn Downloader");
  };
  const cat51 = () => {
    setLink("JSON Bots");
  };
  const cat52 = () => {
    setLink("Link Shortener Bots");
  };
  const cat53 = () => {
    setLink("Markdown Generator Bots");
  };
  const cat54 = () => {
    setLink("Media Fire Bots");
  };
  const cat55 = () => {
    setLink("Media Info Bots");
  };
  const cat56 = () => {
    setLink("Messages Deleting Bots");
  };
  const cat57 = () => {
    setLink("Movies or Series Download Bot");
  };
  const cat58 = () => {
    setLink("Movies or Series Info Bots");
  };
  const cat59 = () => {
    setLink("Multi Purpose Bots");
  };
  const cat60 = () => {
    setLink("Music or Songs Related Bots");
  };
  const cat61 = () => {
    setLink("MX Player Download Bots");
  };
  const cat62 = () => {
    setLink("OneDrive Uploader");
  };
  const cat63 = () => {
    setLink("Other Bots");
  };
  const cat64 = () => {
    setLink("Password Generator Bots");
  };
  const cat65 = () => {
    setLink("Pawned Bots");
  };
  const cat66 = () => {
    setLink("PDF related Bots");
  };
  const cat67 = () => {
    setLink("Permanent File to Link Bots");
  };
  const cat68 = () => {
    setLink("Photo Finder Bots");
  };
  const cat69 = () => {
    setLink("Ping Bots");
  };
  const cat70 = () => {
    setLink("Product Price Tracker Bots");
  };
  const cat71 = () => {
    setLink("Product Search Bots");
  };
  const cat72 = () => {
    setLink("QR Code Scanner or Generator Bots");
  };
  const cat73 = () => {
    setLink("Quiz Bots");
  };
  const cat74 = () => {
    setLink("Reddit Bots");
  };
  const cat75 = () => {
    setLink("Reminder Bots");
  };
  const cat76 = () => {
    setLink("Remove Keyboard from Group Bot");
  };
  const cat77 = () => {
    setLink("Renamer Bots");
  };
  const cat78 = () => {
    setLink("Reverse Image Search Bots");
  };
  const cat79 = () => {
    setLink("RSS Bots");
  };
  const cat80 = () => {
    setLink("Screenshot Generate Bots");
  };
  const cat81 = () => {
    setLink("Secret Message Bot");
  };
  const cat82 = () => {
    setLink("Session String Generator Bot");
  };
  const cat83 = () => {
    setLink("Spam Protection Bots");
  };
  const cat84 = () => {
    setLink("Specific Language Blocker");
  };
  const cat85 = () => {
    setLink("Sports Bots");
  };
  const cat86 = () => {
    setLink("Stickers & Gifs Bots");
  };
  const cat87 = () => {
    setLink("Study Materials Bots");
  };
  const cat88 = () => {
    setLink("Subtitle Bots");
  };
  const cat89 = () => {
    setLink("Tag Alerts Bot");
  };
  const cat90 = () => {
    setLink("Telegram Backend Bots");
  };
  const cat91 = () => {
    setLink("Telegram Creation Date Finder Bots");
  };
  const cat92 = () => {
    setLink("Telegraph Bots");
  };
  const cat93 = () => {
    setLink("Temporary Mail Bots");
  };
  const cat94 = () => {
    setLink("Text Formatting Bots");
  };
  const cat95 = () => {
    setLink("Text Formatting Bots");
  };
  const cat96 = () => {
    setLink("Text Sticker Bots");
  };
  const cat97 = () => {
    setLink("Thumbnail Changer Bots");
  };
  const cat98 = () => {
    setLink("Tiktok Video Downloader");
  };
  const cat99 = () => {
    setLink("Torrent Downloader Bots");
  };
  const cat100 = () => {
    setLink("Torrent Search Bots");
  };
  const cat101 = () => {
    setLink("Translate Bots");
  };
  const cat102 = () => {
    setLink("Transloader Bots");
  };
  const cat103 = () => {
    setLink("Truecaller Bots");
  };
  const cat104 = () => {
    setLink("Unzip or Unrar Bots");
  };
  const cat105 = () => {
    setLink("Url Uploader Bots");
  };
  const cat106 = () => {
    setLink("Usefull Bots");
  };
  const cat107 = () => {
    setLink("User ID Finder Bots");
  };
  const cat108 = () => {
    setLink("Verified Bots");
  };
  const cat109 = () => {
    setLink("Video Compressor Bots");
  };
  const cat110 = () => {
    setLink("Voot Downloader Bots");
  };
  const cat111 = () => {
    setLink("Watermark Bots");
  };
  const cat112 = () => {
    setLink("Weather Bots");
  };
  const cat113 = () => {
    setLink("Website Screenshot Bots");
  };
  const cat114 = () => {
    setLink("YouTube Audio Downloader Bots");
  };
  const cat115 = () => {
    setLink("YouTube Bots");
  };
  const cat116 = () => {
    setLink("YouTube Playlist Downloader");
  };
  const cat117 = () => {
    setLink("YouTube Video Download Bots");
  };
  const cat118 = () => {
    setLink("Zee5 Downloader Bots");
  };
  const cat119 = () => {
    setLink("Zip Bots");
  };

  return (
    <div>
      <a
        style={{ textDecoration: "none", color: "limegreen" }}
        href="https://botlist.infotelbot.com"
      >
        <h1>Search Telegram Bot</h1>
      </a>
      <div class="dropdown">
        <button class="dropbtn">Select category ↓</button>
        <div class="dropdown-content">
          <button onClick={cat1}>Ai Bots</button>
          <button onClick={cat2}>Android Tech News Bots</button>
          <button onClick={cat3}>Anime Downloading</button>
          <button onClick={cat4}>Anonymous Chatting Bots</button>
          <button onClick={cat5}>Anonymous Sender Bots</button>
          <button onClick={cat6}>Anti Deleted Accounts Bots</button>
          <button onClick={cat7}>Anti Service Messages Bots</button>
          <button onClick={cat8}>Anti Userbots Bots</button>
          <button onClick={cat9}>Anti Virus Bots</button>
          <button onClick={cat10}>APP ID & API HASH Extractor Bots</button>
          <button onClick={cat11}>Application Downloader Bots</button>
          <button onClick={cat12}>Attach Bots</button>
          <button onClick={cat13}>Audio Extractor Bots</button>
          <button onClick={cat14}>Auto Welcome Kick Bots</button>
          <button onClick={cat15}>Automatic Caption for Channel Posts</button>
          <button onClick={cat16}>Automatic Reaction Bots</button>
          <button onClick={cat17}>Bot creator Bots</button>
          <button onClick={cat18}>Compiler Bots</button>
          <button onClick={cat19}>Corona Virus Statistics Bots</button>
          <button onClick={cat20}>DC Finder Bots</button>
          <button onClick={cat21}>Debts Tracker Bots</button>
          <button onClick={cat22}>DelDog Bots</button>
          <button onClick={cat23}>Detach Bot</button>
          <button onClick={cat24}>Discussion Cleaner Bots</button>
          <button onClick={cat25}>Discussion Unpin Bots</button>
          <button onClick={cat26}>DMCA Copyright Delete Bots</button>
          <button onClick={cat27}>Download Button For Channels Bots</button>
          <button onClick={cat28}>Email Tools Bots</button>
          <button onClick={cat29}>Facebook Download Bots</button>
          <button onClick={cat30}>File Converters Bots</button>
          <button onClick={cat31}>File Folder Manager Bots</button>
          <button onClick={cat32}>File storing Bots</button>
          <button onClick={cat33}>File To Link Bots</button>
          <button onClick={cat34}>Filter Bots</button>
          <button onClick={cat35}>Force Subscribe Bots</button>
          <button onClick={cat36}>Game Bots</button>
          <button onClick={cat37}>GDrive Bots</button>
          <button onClick={cat38}>Gif Finder Bots</button>
          <button onClick={cat39}>Gist Bots</button>
          <button onClick={cat40}>GitHub Downloader Bots</button>
          <button onClick={cat41}>GPLinks Shortener Bots</button>
          <button onClick={cat42}>Group Filter Bots</button>
          <button onClick={cat43}>Group Manager Bots</button>
          <button onClick={cat44}>Hotstar Bots</button>
          <button onClick={cat45}>Image To PDF Bots</button>
          <button onClick={cat46}>Image to Text (OCR) Bots</button>
          <button onClick={cat47}>Inline Bots</button>
          <button onClick={cat48}>Instagram Download Bots</button>
          <button onClick={cat49}>Instant View Generator Bots</button>
          <button onClick={cat50}>Jio Saavn Downloader</button>
          <button onClick={cat51}>JSON Bots</button>
          <button onClick={cat52}>Link Shortener Bots</button>
          <button onClick={cat53}>Markdown Generator Bots</button>
          <button onClick={cat54}>Media Fire Bots</button>
          <button onClick={cat55}>Media Info Bots</button>
          <button onClick={cat56}>Messages Deleting Bots</button>
          <button onClick={cat57}>Movies or Series Download Bot</button>
          <button onClick={cat58}>Movies or Series Info Bots</button>
          <button onClick={cat59}>Multi Purpose Bots</button>
          <button onClick={cat60}>Music or Songs Related Bots</button>
          <button onClick={cat61}>MX Player Download Bots</button>
          <button onClick={cat62}>OneDrive Uploader</button>
          <button onClick={cat63}>Other Bots</button>
          <button onClick={cat64}>Password Generator Bots</button>
          <button onClick={cat65}>Pawned Bots</button>
          <button onClick={cat66}>PDF related Bots</button>
          <button onClick={cat67}>Permanent File to Link Bots</button>
          <button onClick={cat68}>Photo Finder Bots</button>
          <button onClick={cat69}>Ping Bots</button>
          <button onClick={cat70}>Product Price Tracker Bots</button>
          <button onClick={cat71}>Product Search Bots</button>
          <button onClick={cat72}>QR Code Scanner or Generator Bots</button>
          <button onClick={cat73}>Quiz Bots</button>
          <button onClick={cat74}>Reddit Bots</button>
          <button onClick={cat75}>Reminder Bots</button>
          <button onClick={cat76}>Remove Keyboard from Group Bot</button>
          <button onClick={cat77}>Rename Bots</button>
          <button onClick={cat78}>Reverse Image Search Bots</button>
          <button onClick={cat79}>RSS Bots</button>
          <button onClick={cat80}>Screenshot Generate Bots</button>
          <button onClick={cat81}>Secret Message Bot</button>
          <button onClick={cat82}>Session String Generator Bot</button>
          <button onClick={cat83}>Spam Protection Bots</button>
          <button onClick={cat84}>Specific Language Blocker</button>
          <button onClick={cat85}>Sports Bots</button>
          <button onClick={cat86}>Stickers & Gifs Bots</button>
          <button onClick={cat87}>Study Materials Bots</button>
          <button onClick={cat88}>Subtitle Bots</button>
          <button onClick={cat89}>Tag Alerts Bot</button>
          <button onClick={cat90}>Telegram Backend Bots</button>
          <button onClick={cat91}>Telegram Creation Date Finder Bots</button>
          <button onClick={cat92}>Telegraph Bots</button>
          <button onClick={cat93}>Temporary Mail Bots</button>
          <button onClick={cat94}>Text Formatting Bots</button>
          <button onClick={cat95}>Text Formatting Bots</button>
          <button onClick={cat96}>Text Sticker Bots</button>
          <button onClick={cat97}>Thumbnail Changer Bots</button>
          <button onClick={cat98}>Tiktok Video Downloader</button>
          <button onClick={cat99}>Torrent Downloader Bots</button>
          <button onClick={cat100}>Torrent Search Bots</button>
          <button onClick={cat101}>Translate Bots</button>
          <button onClick={cat102}>Transloader Bots</button>
          <button onClick={cat103}>Truecaller Bots</button>
          <button onClick={cat104}>Unzip or Unrar Bots</button>
          <button onClick={cat105}>Url Uploader Bots</button>
          <button onClick={cat106}>Usefull Bots</button>
          <button onClick={cat107}>User ID Finder Bots</button>
          <button onClick={cat108}>Verified Bots</button>
          <button onClick={cat109}>Video Compressor Bots</button>
          <button onClick={cat110}>Voot Downloader Bots</button>
          <button onClick={cat111}>Watermark Bots</button>
          <button onClick={cat112}>Weather Bots</button>
          <button onClick={cat113}>Website Screenshot Bots</button>
          <button onClick={cat114}>YouTube Audio Downloader Bots</button>
          <button onClick={cat115}>YouTube Bots</button>
          <button onClick={cat116}>YouTube Playlist Downloader</button>
          <button onClick={cat117}>YouTube Video Download Bots</button>
          <button onClick={cat118}>Zee5 Downloader Bots</button>
          <button onClick={cat119}>Zip Bots</button>
        </div>
      </div>

      <div className="catname">
        <h1>Showing Results For {link}</h1>
      </div>

      <div className="list">
        <table>
          {bots.map((bot) => (
            <BotList id={bot.id} bot={bot.bot.name} />
          ))}
        </table>
      </div>
    </div>
  );
}

export default Category;

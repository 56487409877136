import React, { useState } from "react";
import "./NewBot.css";
import db from "./firebase";
import uuid from "react-uuid";

function NewBot() {
  const [data, setData] = useState([
    {
      collec: "Ai Bots",
      bname: "",
    },
  ]);

  var arr = [
    "Ai Bots",
    "Android Tech News Bots",
    "Anime Downloading",
    "Anonymous Chatting Bots",
    "Anonymous Sender Bots",
    "Anti Deleted Accounts Bot",
    "Anti Service Messages Bots",
    "Anti Userbots Bots",
    "Anti Virus Bots",
    "APP ID & API HASH Extractor Bots",
    "Application Downloader Bots",
    "Attach Bots",
    "Audio Extractor Bots",
    "Auto Welcome Kick Bots",
    "Automatic Caption for Channel Posts",
    "Automatic Reaction Bots",
    "Bot creator Bots",
    "Compiler Bots",
    "Corona Virus Statistics Bots",
    "DC Finder Bots",
    "Debts Tracker Bots",
    "DelDog Bots",
    "Detach Bot",
    "Discussion Cleaner Bots",
    "Discussion Unpin Bots",
    "DMCA Copyright Delete Bots",
    "Download Button For Channels Bots",
    "Email Tools Bots",
    "Facebook Download Bots",
    "File Converters Bots",
    "File Folder Manager Bots",
    "File storing Bots",
    "File To Link Bots",
    "Filter Bots",
    "Force Subscribe Bots",
    "Game Bots",
    "GDrive Bots",
    "Gif Finder Bots",
    "Gist Bots",
    "GitHub Downloader Bots",
    "GPLinks Shortener Bots",
    "Group Filter Bots",
    "Group Manager Bots",
    "Hotstar Bots",
    "Image To PDF Bots",
    "Image to Text (OCR) Bots",
    "Inline Bots",
    "Instagram Download Bots",
    "Instant View Generator Bots",
    "Jio Saavn Downloader",
    "JSON Bots",
    "Link Shortener Bots",
    "Markdown Generator Bots",
    "Media Fire Bots",
    "Media Info Bots",
    "Messages Deleting Bots",
    "Movies or Series Download Bot",
    "Movies or Series Info Bots",
    "Multi Purpose Bots",
    "Music or Songs Related Bots",
    "MX Player Download Bots",
    "OneDrive Uploader",
    "Other Bots",
    "Password Generator Bots",
    "Pawned Bots",
    "PDF related Bots",
    "Permanent File to Link Bots",
    "Photo Finder Bots",
    "Ping Bots",
    "Product Price Tracker Bots",
    "Product Search Bots",
    "QR Code Scanner or Generator Bots",
    "Quiz Bots",
    "Reddit Bots",
    "Reminder Bots",
    "Remove Keyboard from Group Bot",
    "Renamer Bots",
    "Reverse Image Search Bots",
    "RSS Bots",
    "Screenshot Generate Bots",
    "Search Bots",
    "Secret Message Bot",
    "Session String Generator Bot",
    "Spam Protection Bots",
    "Specific Language Blocker",
    "Sports Bots",
    "Stickers & Gifs Bots",
    "Study Materials Bots",
    "Subtitle Bots",
    "Tag Alerts Bot",
    "Telegram Backend Bots",
    "Telegram Creation Date Finder Bots",
    "Telegraph Bots",
    "Temporary Mail Bots",
    "Text Formatting Bots",
    "Text Sticker Bots",
    "Thumbnail Changer Bots",
    "Tiktok Video Downloader",
    "Torrent Downloader Bots",
    "Torrent Search Bots",
    "Torrent Search Bots",
    "Translate Bots",
    "Transloader Bots",
    "Truecaller Bots",
    "Unzip or Unrar Bots",
    "Url Uploader Bots",
    "Usefull Bots",
    "User ID Finder Bots",
    "Verified Bots",
    "Video Compressor Bots",
    "Voot Downloader Bots",
    "Watermark Bots",
    "Weather Bots",
    "Website Screenshot Bots",
    "YouTube Audio Downloader Bots",
    "YouTube Bots",
    "YouTube Playlist Downloader",
    "YouTube Video Download Bots",
    "Zee5 Downloader Bots",
    "Zip Bots",
  ];

  const rID = uuid();

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData((prev) => {
      if (name === "bname") {
        return {
          bname: value,
          collec: prev.collec,
          time: Date.now(),
        };
      } else {
        alert("Something went wrong");
      }
    });
  };

  const selectCol = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData((prev) => {
      if (name === "cat") {
        return {
          bname: prev.bname,
          collec: value,
          time: prev.time,
        };
      }
    });
    // console.log(col);
    console.log("Hello");
  };

  const onSub = () => {
    db.collection(data.collec).doc(rID).set({
      name: data.bname,
      time: data.time,
    });
    console.log(data.collec);

    alert("Bot Added");
  };

  // arr.map((col) => {
  //   console.log(col);
  // });

  return (
    <div className="bot">
      <a
        style={{ textDecoration: "none", color: "limegreen" }}
        href="https://botlist.infotelbot.com"
      >
        <h1>Add New Bot</h1>
      </a>

      <div className="form">
        <div>
          <h4 className="place">Enter username of the bot</h4>
          @
          <input
            className="name"
            type="text"
            name="bname"
            placeholder="Example: infotelgroup"
            onChange={inputEvent}
          />
        </div>
        <div>
          <select className="select" onChange={selectCol} name="cat">
            <option value="" disabled selected>
              Select a Category
            </option>
            {arr.map((col, index) => (
              <option key={index} value={col}>
                {col}
              </option>
            ))}
          </select>
        </div>

        <button className="sub" onClick={onSub}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default NewBot;

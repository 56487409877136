import React, { useEffect, useState } from "react";
import db from "./firebase";
import "./BotList.css";

function BotList({ id, bot }) {
  return (
    <div>
      <th>
        <a
          style={{ textDecoration: "none ", color: "black" }}
          href={`https://telegram.dog/${bot}`}
        >
          @{bot}
        </a>
      </th>
    </div>
  );
}

export default BotList;
